import { Box, Text } from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';

import { getDownloadLink } from '@/services/link.service';
import type { PopiaNoticeField } from '@/types/config/config.types';

import Styles from './popia-notice.module.scss';

export const PopiaNotice: React.FC<{ documentPaiaPopia: PopiaNoticeField }> = ({
  documentPaiaPopia,
}) => {
  return (
    <Box className={Styles.popiaNotice}>
      <Text>Your privacy is important to us.</Text>
      <Link
        href={getDownloadLink(documentPaiaPopia?.popia_notice)}
        target="_blank"
        rel="noopener noreferrer"
        className={Styles.popiaLink}
      >
        Read how we protect and process your personal information.
      </Link>
    </Box>
  );
};
